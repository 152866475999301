// Common css start
.mr_5{
    margin-right: 5px !important;
}
.mr_10 {
    margin-right: 10px !important;
}
.mr_15 {
    margin-right: 15px !important;
}

.mt_5{
    margin-top: 5px !important;
}
.mt_10{
    margin-top: 10px !important;
}
.mt_15{
    margin-top: 15px !important;
}
.mt_20{
    margin-top: 20px !important;
}

.mb_5{
    margin-bottom: 5px !important;
}
.mb_10{
    margin-bottom: 10px !important;
}
.mb_15{
    margin-bottom: 15px !important;
}
.mb_20{
    margin-bottom: 20px !important;
}

.text_right{
    text-align: right !important;
}
.text_center{
    text-align: center !important;
}
.text_left{
    text-align: left !important;
}
.w_100{
    width: 100%;
}
.d_inline_block{
    display: inline-block;
}
.fs_14{
    font-size: 12px;
}
.d_flex{
    display: flex;
}
.justify_content_between{
    justify-content: space-between;
}
.align_items_center{
    align-items: center;
}
.action_btn {
    padding: 4px 10px !important;

    &.view {
        background-color: #1AA053 !important;
        border-color: #1AA053 !important;
        color: #fff !important;

        &:hover {
            background-color: #1AA053 !important;
            border-color: #1AA053 !important;
            color: #fff !important;
        }
    }

    &.edit {
        background-color: #3A57E8 !important;
        border-color: #3A57E8 !important;
        color: #fff !important;

        &:hover {
            background-color: #3A57E8 !important;
            border-color: #3A57E8 !important;
            color: #fff !important;
        }
    }

    &.delete {
        background-color: #D50005 !important;
        border-color: #D50005 !important;
        color: #fff !important;

        &:hover {
            background-color: #D50005 !important;
            border-color: #D50005 !important;
            color: #fff !important;
        }
    }
}


// Dashboard css start
.dashboard_action{
    display: flex;
    .active_btn{
        background-color:#01AF62 !important;
        border-color: #01AF62 !important;
        color: #fff !important;
        &:hover{
            background-color: #01AF62 !important;
            border-color: #01AF62 !important;
            color: #fff !important;
        }
    }
    .action_btn{
        padding: 4px 10px !important;
        &.view{
            background-color: #1AA053 !important;
            border-color: #1AA053 !important;
            color: #fff !important;
        
            &:hover {
                background-color: #1AA053 !important;
                border-color: #1AA053 !important;
                color: #fff !important;
            }
        }
        &.edit{
            background-color: #3A57E8 !important;
            border-color: #3A57E8 !important;
            color: #fff !important;
        
            &:hover {
                background-color: #3A57E8 !important;
                border-color: #3A57E8 !important;
                color: #fff !important;
            }
        }
        &.delete{
            background-color: #D50005 !important;
            border-color: #D50005 !important;
            color: #fff !important;
        
            &:hover {
                background-color: #D50005 !important;
                border-color: #D50005 !important;
                color: #fff !important;
            }
        }
    }
}

.dashboard_top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .title{
        font-size: 24px;
        font-weight: 400;
        color: #1B1B1B;
    }
    .add_data_dashboard{
        display: flex;
    }
}
.dashboard_tops {
    margin-bottom: 20px;

    .title {
        font-size: 24px;
        font-weight: 400;
        color: #1B1B1B;
    }

    .add_data_dashboard {
        display: flex;
    }
}

// Subscription Details css start
.subscription_details{
    padding: 10px 0;
    width: 100%;
    border-bottom: solid 1px rgba($color: #000000, $alpha: .2);
    .label{
        font-size: 16px;
        font-weight: 500;
        color: rgba($color: #1B1B1B, $alpha: .6);
        margin-bottom: 10px;
    }
    .value{
        font-size: 16px;
        font-weight: 500;
        color: rgba($color: #1B1B1B, $alpha: 1);
    }
    .list{
        ul{
            margin: 0;
            li{
                font-size: 16px;
                font-weight: 500;
                color: rgba($color: #1B1B1B, $alpha: 1);
                margin-bottom: 5px;
            }
        }
    }
}


// Login screen css start
.main_wrapper{
    width: 100%;
    height: 100vh;
    background-color: #F9F9F9;
    display: flex;
    justify-content: center;
    align-items: center;
    .login_container{
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        .login_img_card{
            background-color: #fff;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: auto;
            }
        }
        .credentials_card{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            .card{
                max-width: 400px;
            
                .title{
                    font-size: 52px;
                    font-weight: 600;
                    color: #1B1B1B;
                    margin-bottom: 0;
                }
                .para{
                    font-size: 15px;
                    font-weight: 400;
                    color: rgba($color: #1B1B1B, $alpha: .6);
                    margin-bottom: 40px;
                }
                .password{
                    height: 40px;
                }
                .login_btn{
                    margin-top: 20px;
                    max-width: 100%;
                    width: 100%;
                    height: 36px;
                }
            }
        }
    }
}

// materials page css start
.materials_inner_card{
    padding: 15px 20px;
    background-color: rgba($color: #27A9E0, $alpha: 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 15px;
    .content{
        .title{
            font-size: 16px;
            font-weight: 700;
            color: #000000;
            margin: 0;
            margin-bottom: 5px;
        }
        .para{
            font-size: 13px;
            font-weight: 400;
            color: rgba($color: #000000, $alpha: .8);
            margin: 0;
        }
    }
}
.video_drawer_title{
    .title{
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        margin: 0;
    }
    .sub_title{
        font-size: 16px;
        font-weight: 500;
        color: rgba($color: #000000, $alpha: .6);
        margin-top: 10px;
    }   
    .description{
        font-size: 14px;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: .8);
        margin-bottom: 20px;
        line-height: 140%;
    }
}
.document_upload{
    .ant-upload{
        width: 100% !important;
        button{
            width: 100% !important;
            height: 38px;
        }
    }
    // .ant-upload-wrapper{
        .ant-upload-list{
            .ant-upload-list-item{
                height: 30px;
                padding: 0 10px;
                border-radius: 5px;
            }
        }
    // }
}

.materials_card{
    display: flex;
    align-items: center;
    .icon{
        width: 60px;
        height: 60px;
        background-color: rgba($color: #27A9E0, $alpha: 0.1);
        border-radius: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            width: 40px;
            height: 40px;
        }
    }
}


// Antd css start
.ant-menu-dark{
    background-color: #35A8DF !important;
}
.ant-menu-dark .ant-menu-item-selected{
    background-color: #ffffff !important;
    color: #35A8DF !important;
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    background: #3cb2eb!important;
}
:where(.css-dev-only-do-not-override-2hjacz).ant-col {
    min-height: auto !important;
}
.css-2hjacz {
    min-height: auto !important;
}
:where(.css-2hjacz).ant-col {
    min-height: auto !important;
}